import Lamp from '../components/Lamp';
import bigPadlock from '../assets/big_padlock800.png';
import security from '../assets/security800.png';
import biometrics from '../assets/biometrics800.png';
import cryptography from '../assets/cryptography800.png';
import api from '../assets/api800.png';
import Card from '../components/Card';

const Home = () => {
  return (
    <>
      <Lamp />
      <div className="paragraph-container">
        <h3>Ditch the passwords, embrace the future.</h3>
        <p>
          BEE Secure Tech is a leading cybersecurity company offering innovative
          passwordless biometric authentication solutions.
        </p>
        <p>
          We empower businesses and individuals with secure, convenient access
          using biometrics, and other advanced technologies.
        </p>
        <p>
          Stop struggling with password fatigue and worrying about data
          breaches. Experience the next level of security with BEE Secure Tech.
        </p>
      </div>
      <div className="card-container">
        <Card>
          <img src={security} alt="security" className="card-child" />
          <div className="left-card-content">
            <h2>Strengthened Security</h2>
            <p>Secure authentication, no password required.</p>
          </div>
        </Card>

        <Card>
          <div className="right-card-content">
            <h2>Biometrics First</h2>
            <p>Unlocking the power of biometrics everywhere.</p>
          </div>
          <img src={biometrics} alt="biometrics" className="card-child" />
        </Card>
        <Card>
          <img src={api} alt="api" className="card-child" />
          <div className="left-card-content">
            <h2>Seamless Integration</h2>
            <p>With our API based service you can integrate with ease.</p>
          </div>
        </Card>
        <Card>
          <div className="right-card-content">
            <h2>Multi-Factor by Design</h2>
            <p>Built-in safeguards for a seamless user experience.</p>
          </div>
          <img src={bigPadlock} alt="bigPadlock" className="card-child" />
        </Card>
        <Card>
          <img src={cryptography} alt="cryptography" className="card-child" />
          <div className="left-card-content">
            <h2>Cutting-Edge Cryptography</h2>
            <p>Every authentication request is secure and reliable.</p>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Home;
