import logo from '../assets/logo.png';
import './css/Lamp.css';

const Lamp = () => {
  return (
    <div className="lamp-container">
      <div className="lamp" />
      <img src={logo} alt="logo" className="lamp-img" />
    </div>
  );
};

export default Lamp;
