const LoggedIn = () => {
  return (
    <div className='container'>
      <div className='paragraph-container'>
        <h2>
          Welcome to the passwordless world. We're glad you could join us.
        </h2>
      </div>
    </div>
  );
};

export default LoggedIn;
