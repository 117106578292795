import BounceLoader from 'react-spinners/BounceLoader';
import './css/Loader.css'; // Assuming you have a CSS file named Loader.css

interface LoaderProps {
  loading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ loading }) => (
  <div className='loader-container'>
    <BounceLoader color='#123abc' loading={loading} size={60} />
  </div>
);
export default Loader;
