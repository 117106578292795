const About = () => {
  return (
    <div className="paragraph-container">
      <h3>Securing Your World, Simplified</h3>
      <p>
        At BEE Secure Tech, we believe security shouldn't be complicated. We're
        a leading provider of passwordless authentication solutions, offering a
        robust and user-friendly approach to protecting your digital world.
      </p>
      <br />
      <h3>Our Mission:</h3>
      <p>
        We strive to revolutionise the way individuals and businesses experience
        security by eliminating the vulnerabilities of passwords and offering an
        effortlessly secure alternative.
      </p>
      <br />
      <h3>The Power of Biometrics and Cryptography:</h3>
      <p>
        Our innovative platform leverages the power of biometrics, such as
        fingerprint and facial recognition, combined with cutting-edge
        cryptography, to create a secure multi-factor authentication (MFA)
        experience. This eliminates the reliance on weak and easily stolen
        passwords, significantly reducing the risk of breaches and unauthorised
        access.
      </p>
      <br />
      <h3>Experience the Simplicity:</h3>
      <p>
        Our user-friendly mobile app provides a one-stop shop for all your
        authentication needs. With a few intuitive taps, users can securely log
        in to online accounts and access their applications.
      </p>
      <br />
      <h3>Empowering Your Business:</h3>
      <p>
        We understand that integration needs vary. That's why we offer a simple
        and well-documented API, allowing your developers to seamlessly
        integrate our secure authentication methods into your existing systems.
        Empowering you to effortlessly add our secure authentication service
        without sacrificing user experience.
      </p>
      <br />
      <h3>Join the Future of Security:</h3>
      <p>
        If you're a business looking to fortify your security posture, BEE
        Secure Tech is the answer. Contact us today to learn more about how we
        can help you achieve a future free from password hassles and security
        concerns.
      </p>
    </div>
  );
};

export default About;
