import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // import your logo
import './css/Navbar.css'; // import your CSS file
import { useState } from 'react';

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <nav className='navbar'>
      <div className='title'>
        <Link className='navbar-link' to='/'>
          <img className='navbar-logo' src={logo} alt='Logo' />
          <h4>BEE Secure Tech</h4>
        </Link>
      </div>
      <div className='menu-icon' onClick={toggleLinks}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={`navbar-links ${showLinks ? 'show' : ''}`}>
        <Link className='navbar-link' to='/' onClick={toggleLinks}>
          Home
        </Link>
        <Link className='navbar-link' to='/insights' onClick={toggleLinks}>
          Insights
        </Link>
        <Link className='navbar-link' to='/about' onClick={toggleLinks}>
          About
        </Link>
        <Link className='navbar-link' to='/log-in' onClick={toggleLinks}>
          Login
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
