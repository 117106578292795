import { useEffect, useMemo } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { type Container, type ISourceOptions } from '@tsparticles/engine';
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from '@tsparticles/slim'; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import Navbar from './components/Navbar';
import Home from './pages/Home';
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Waitlist from './pages/Waitlist';
import About from './pages/About';
import { particlesOptions } from './utils/particlesOptions';
import Footer from './components/Footer';
import Insights from './pages/Insights';
import Login from './pages/Login';
import LoggedIn from './pages/LoggedIn';
import PrivateRoutes from './routes/PrivateRoutes';

const App = () => {
  // const [init, setInit] = useState(false);
  // const [particlesHaveLoaded, setParticlesHaveLoaded] = useState(false);

  // useEffect(() => {
  //   // Delay the rendering of the rest of the components to give the particles time to load
  //   setTimeout(() => {
  //     setParticlesHaveLoaded(true);
  //   }, 1000); // Adjust this value as needed
  // }, []);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    });
    // .then(() => {
    //   setInit(true);
    // });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(container);
  };

  const options: ISourceOptions = useMemo(() => particlesOptions, []);

  // if (!init) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      <Router>
        <Particles
          id='tsparticles'
          particlesLoaded={particlesLoaded}
          options={options}
        />
        <Navbar />
        {/* {particlesHaveLoaded && ( */}
        <>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/insights' element={<Insights />} />
            <Route path='/about' element={<About />} />
            {/* <Route path='/wait-list' element={<Waitlist />} /> */}
            <Route path='/log-in' element={<Login />} />
            <Route element={<PrivateRoutes />}>
              <Route path='/logged-in' element={<LoggedIn />} />
            </Route>
          </Routes>
          <Footer />
        </>
        {/* )} */}
      </Router>
    </>
  );
};

export default App;
