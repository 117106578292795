import { useContext, useState } from 'react';
import './css/EmailForm.css';
import Loader from './Loader';
import { AuthContext } from '../context/auth';
import { Navigate } from 'react-router-dom';

const clientId = 'c8f791a4-fb59-4886-8d98-c3274e2ebe72';
// TODO: hardcode client secret when testing
const clientSecret = '3095c8af-2291-471e-9d1e-20bf523812ac'; //process.env.CLIENT_SECRET;

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const auth = useContext(AuthContext);
  const { setIsLoggedIn, isLoggedIn } = auth;

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // Simple validation to check if the email is in the correct format
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValid) {
      setError('Please enter a valid email address.');
    } else {
      try {
        setLoading(true);
        const response = await fetch(
          `https://dev-api.beesecure.tech/client/v1/${clientId}/customer-login`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-client-secret': clientSecret ?? '',
            },
            body: JSON.stringify({ email }),
            mode: 'cors',
          }
        );

        const json = await response.json();
        const { authorised } = json.data;

        if (!response.ok || !authorised) {
          setError('Sorry, we could not log you in. Please try again.');
          setLoading(false);
        } else {
          setIsLoggedIn(true);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError('Something went wrong. Please try again.');
        setLoading(false);
      }
    }
  };

  if (isLoggedIn) {
    return <Navigate to='/logged-in' />;
  }

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <form className='form-container' onSubmit={handleSubmit}>
          <div className='input-button-wrapper'>
            <label htmlFor='email-input'>Email</label>
            <input
              className='email-form-input'
              type='email'
              id='email-input'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button className='email-form-button' type='submit'>
              Login
            </button>
          </div>
          {error && <p>{error}</p>}
        </form>
      )}
    </>
  );
};

export default LoginForm;
