import LoginForm from '../components/LoginForm';

const Login = () => {
  return (
    <>
      <div className='container'>
        <div className='paragraph-container'>
          <h3>Login</h3>
        </div>
        <br />
        <LoginForm />
      </div>
    </>
  );
};

export default Login;
