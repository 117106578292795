import Card from '../components/Card';
import ninetyFive from '../assets/95.png';
import seventySix from '../assets/76.png';
import eightySix from '../assets/86.png';
import threeMillion from '../assets/3m.png';
import twoThousand from '../assets/2k.png';

const Insights = () => {
  return (
    <>
      <div className="paragraph-container">
        <h3>Unlocking the Future of Security</h3>
        <p>
          In today's digital landscape, the need for robust security has never
          been greater. The good news? The future of authentication is
          passwordless.
        </p>
        <p>
          With the global market expected to hit a colossal £41.7 billion by
          2030 [source: Statista 2024], it's clear businesses are recognising
          the limitations of traditional passwords.
        </p>
        <p>
          It's no surprise then, that a staggering 73% of businesses worldwide
          are actively seeking more secure alternatives [source: PwC 2023].
        </p>
      </div>
      <div className="card-container card-center-align">
        <Card>
          <img src={eightySix} alt="eightySix" className="card-child" />
          <div className="left-card-content">
            <h3>Of web application attacks are from stolen passwords.</h3>
          </div>
        </Card>
        <br />
        <Card>
          <div className="right-card-content">
            <h3>
              Average cost of a data breach for an enterprise organisation.
            </h3>
          </div>
          <img src={threeMillion} alt="threeMillion" className="card-child" />
        </Card>
        <br />
        <Card>
          <img src={seventySix} alt="seventySix" className="card-child" />
          <div className="left-card-content">
            <h3>
              Of online consumers have given up on purchases due to password
              issues.
            </h3>
          </div>
        </Card>
        <br />
        <Card>
          <div className="right-card-content">
            <h3>
              Average company spend per employee per year on cybersecurity.
            </h3>
          </div>
          <img src={twoThousand} alt="twoThousand" className="card-child" />
        </Card>
        <br />
        <Card>
          <img src={ninetyFive} alt="ninetySeven" className="card-child" />
          <div className="left-card-content">
            <h3>Of all web application attacks have a financial motive.</h3>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Insights;
